import { UserIcon } from '@heroicons/react/solid';
import { Team, TeamProductType } from 'interfaces';
import React from 'react';
import { useSelector } from 'store';
import { twMerge } from 'tailwind-merge';

import { useTranslation } from 'hooks/useTypedTranslation';

import TeamIcon from './TeamIcon';

interface TeamBannerProps {
  onClick?: () => void;
}

export const TeamBanner: React.FC<TeamBannerProps> = ({ onClick }) => {
  const { t } = useTranslation('common');

  const team = useSelector<Team>(state => state.team.team);
  const isAdmin = team?.isAdmin;

  const handleClick = () => {
    if (isAdmin && onClick) {
      onClick();
    }
  };

  const getProductTypeLabel = (productId: TeamProductType) => {
    switch (productId) {
      case TeamProductType.Freemium:
        return t('Freemium');

      case TeamProductType.Premium:
        return t('Premium');
    }
  };

  if (team) {
    return (
      <button
        className={twMerge(
          isAdmin && onClick ? 'cursor hover:shadow' : 'cursor-default',
          'group mb-6 flex w-full items-center rounded-lg border border-gray-200 px-2 py-2 text-left text-sm text-gray-700 shadow-sm'
        )}
        onClick={handleClick}
      >
        <TeamIcon displayName={team.displayName} size={36} url={team.iconUrl} />
        <div className="ml-2 truncate text-sm font-medium text-gray-900">
          {team.displayName}
          <div className="mt-1 flex text-sm text-gray-400">
            {getProductTypeLabel(team.productId)}
            <UserIcon className="h-4 px-2" />
            {team.numberOfLicenses}
          </div>
        </div>
      </button>
    );
  } else {
    return null;
  }
};

export default TeamBanner;
