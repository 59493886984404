import React, { useMemo } from 'react';

import { getInitials } from '../../utils';

interface TeamIconProps {
  displayName: string;
  size?: number;
  url?: string;
}

// ESLint: Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getStyles = (size: number, initials: string): any => {
  return {
    img: {
      position: 'relative',
      width: size,
      height: size,
      borderRadius: '50%',
      border: '1px solid rgb(229, 231, 235)'
    },
    parent: {
      backgroundColor: '#efefef',
      color: '#666',
      width: size,
      height: size,
      borderRadius: '50%',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    swatch: {
      fontSize: size / (1.4 * Math.max([...initials].length, 2)),
      position: 'absolute',
      fontFamily: 'sans-serif',
      userSelect: 'none'
    }
  };
};

export const TeamIcon: React.FC<TeamIconProps> = ({ displayName, url, size = 24 }) => {
  const initials = useMemo(() => getInitials(displayName), [displayName]);
  // ESLint: React Hook useMemo has a missing dependency: 'initials'. Either include it or remove the dependency array.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const styles = useMemo(() => getStyles(size, initials), [displayName, size]);

  return (
    <div style={styles.parent}>
      <div aria-hidden="true" style={styles.swatch}>
        {initials}
      </div>
      <img
        style={styles.img}
        src={url || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='}
        alt={displayName}
      />
    </div>
  );
};

export default TeamIcon;
